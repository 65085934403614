import React, { useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Collapse } from "reactstrap";
// Import Data
import NavData from "../LayoutMenuData";
import withRouter from "../../../Common/Components/withRouter";
import { Store } from "Store";
import { LAYOUT_TYPES, SIDEBAR_VISIBILITY_TYPES } from "Constant/layout";
import NewChatIcon from "../../../assets/images/Icon/AddIcon.svg";
import { ChatType } from "Common/helper/helper";

const VerticalLayout = (props: any) => {
  const navData = NavData().props.children;
  const path = props.router.location.pathname;
  /*
layout settings
*/
  const { leftSidebarSizeType, sidebarVisibilityType, layoutType } =
    Store.Layout((state: any) => ({
      leftSidebarSizeType: state.leftSidebarSizeType,
      sidebarVisibilityType: state.sidebarVisibilityType,
      layoutType: state.layoutType,
    }));

  const {
    setPromptCardStatus,
    setChatSessionId,
    setChatTypeStatus,
    setChatModalSubmitStatus,
  } = Store.Chat();

  const navigate = useNavigate();

  //vertical and semibox resize events
  const resizeSidebarMenu = useCallback(() => {
    var windowSize = document.documentElement.clientWidth;
    var hamburgerIcon = document.querySelector(".hamburger-icon");

    if (windowSize >= 1025) {
      if (
        document.documentElement.getAttribute("data-layout") ===
        LAYOUT_TYPES.VERTICAL
      ) {
        document.documentElement.setAttribute(
          "data-sidebar-size",
          leftSidebarSizeType
        );
      }
      if (
        document.documentElement.getAttribute("data-layout") ===
        LAYOUT_TYPES.SEMI_BOX
      ) {
        document.documentElement.setAttribute(
          "data-sidebar-size",
          leftSidebarSizeType
        );
      }
      if (
        (sidebarVisibilityType === SIDEBAR_VISIBILITY_TYPES.SHOW ||
          layoutType === LAYOUT_TYPES.VERTICAL ||
          layoutType === LAYOUT_TYPES.TWO_COLUMN) &&
        document.querySelector(".hamburger-icon")
      ) {
        if (hamburgerIcon != null) hamburgerIcon.classList.remove("open");
      } else {
        if (hamburgerIcon != null) hamburgerIcon.classList.add("open");
      }
    } else if (windowSize < 1025 && windowSize > 767) {
      document.body.classList.remove("twocolumn-panel");
      if (
        document.documentElement.getAttribute("data-layout") ===
        LAYOUT_TYPES.VERTICAL
      ) {
        document.documentElement.setAttribute("data-sidebar-size", "sm");
      }
      if (
        document.documentElement.getAttribute("data-layout") ===
        LAYOUT_TYPES.SEMI_BOX
      ) {
        document.documentElement.setAttribute("data-sidebar-size", "sm");
      }
      if (hamburgerIcon) {
        hamburgerIcon.classList.add("open");
      }
    } else if (windowSize <= 767) {
      document.body.classList.remove("vertical-sidebar-enable");
      if (
        document.documentElement.getAttribute("data-layout") !==
        LAYOUT_TYPES.HORIZONTAL
      ) {
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
      if (hamburgerIcon) {
        hamburgerIcon.classList.add("open");
      }
    }
  }, [leftSidebarSizeType, sidebarVisibilityType, layoutType]);

  useEffect(() => {
    window.addEventListener("resize", resizeSidebarMenu, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resizeSidebarMenu]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const initMenu = () => {
      const pathName = process.env.PUBLIC_URL + path;
      const fullPath = pathName.split("/");
      let firstPath: any = "";
      if (fullPath.length >= 2) {
        firstPath = "/" + fullPath[1];
      } else {
        firstPath = pathName;
      }
      const ul = document.getElementById("navbar-nav");
      if (ul) {
        const items = ul.getElementsByTagName("a");
        let itemsArray = Array.from(items); // converts NodeList to Array
        removeActivation(itemsArray);
        let matchingMenuItem = itemsArray.find((x) => {
          return x.pathname === firstPath;
        });
        if (matchingMenuItem) {
          activateParentDropdown(matchingMenuItem);
        }
      }
    };
    if (props.layoutType === LAYOUT_TYPES.VERTICAL) {
      initMenu();
    }
  }, [path, props.layoutType]);

  function activateParentDropdown(item: any) {
    item.classList.add("active");
    let parentCollapseDiv = item.closest(".collapse.menu-dropdown");

    if (parentCollapseDiv) {
      // to set aria expand true remaining
      parentCollapseDiv.classList.add("show");
      parentCollapseDiv.parentElement.children[0].classList.add("active");
      parentCollapseDiv.parentElement.children[0].setAttribute(
        "aria-expanded",
        "true"
      );
      if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
        parentCollapseDiv.parentElement
          .closest(".collapse")
          .classList.add("show");
        if (
          parentCollapseDiv.parentElement.closest(".collapse")
            .previousElementSibling
        )
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.classList.add("active");
        if (
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.closest(".collapse")
        ) {
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.closest(".collapse")
            .classList.add("show");
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.closest(".collapse")
            .previousElementSibling.classList.add("active");
        }
      }
      return false;
    }
    return false;
  }

  const removeActivation = (items: any) => {
    let actiItems = items.filter((x: any) => x.classList.contains("active"));

    actiItems.forEach((item: any) => {
      if (item.classList.contains("menu-link")) {
        if (!item.classList.contains("active")) {
          item.setAttribute("aria-expanded", false);
        }
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove("show");
        }
      }
      if (item.classList.contains("nav-link")) {
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove("show");
        }
        item.setAttribute("aria-expanded", false);
      }
      item.classList.remove("active");
    });
  };

  return (
    <React.Fragment>
      {/* menu Items */}
      {(navData || []).map((item: any, key: any) => {
        return (
          <React.Fragment key={key}>
            {/* Main Header */}
            {item["isHeader"] ? (
              <li className="menu-title">
                <span data-key="t-menu">{item.label} </span>
              </li>
            ) : item.subItems ? (
              <li className="nav-item">
                <Link
                  onClick={item.click}
                  className="nav-link menu-link"
                  to={item.link ? item.link : "/#"}
                  data-bs-toggle="collapse"
                >
                  <i className={item.icon}></i>
                  <span data-key="t-apps">{item.label}</span>
                  {item.badgeName ? (
                    <span
                      className={"badge badge-pill bg-" + item.badgeColor}
                      data-key="t-new"
                    >
                      {item.badgeName}
                    </span>
                  ) : null}
                </Link>
                <Collapse
                  className="menu-dropdown"
                  isOpen={item.stateVariables}
                  id="sidebarApps"
                >
                  <ul className="nav nav-sm flex-column test">
                    {/* subItms  */}
                    {item.subItems &&
                      (item.subItems || []).map((subItem: any, key: any) => (
                        <React.Fragment key={key}>
                          {!subItem.isChildItem ? (
                            <li className="nav-item">
                              <Link
                                to={subItem.link ? subItem.link : "/#"}
                                className="nav-link"
                              >
                                {subItem.label}
                                {subItem.badgeName ? (
                                  <span
                                    className={
                                      "badge badge-pill bg-" +
                                      subItem.badgeColor
                                    }
                                    data-key="t-new"
                                  >
                                    {subItem.badgeName}
                                  </span>
                                ) : null}
                              </Link>
                            </li>
                          ) : (
                            <li className="nav-item">
                              <Link
                                onClick={subItem.click}
                                className="nav-link"
                                to="/#"
                                data-bs-toggle="collapse"
                              >
                                {subItem.label}
                                {subItem.badgeName ? (
                                  <span
                                    className={
                                      "badge badge-pill bg-" +
                                      subItem.badgeColor
                                    }
                                    data-key="t-new"
                                  >
                                    {subItem.badgeName}
                                  </span>
                                ) : null}
                              </Link>
                              <Collapse
                                className="menu-dropdown"
                                isOpen={subItem.stateVariables}
                                id="sidebarEcommerce"
                              >
                                <ul className="nav nav-sm flex-column">
                                  {/* child subItms  */}
                                  {subItem.childItems &&
                                    (subItem.childItems || []).map(
                                      (childItem: any, key: any) => (
                                        <React.Fragment key={key}>
                                          {!childItem.childItems ? (
                                            <li className="nav-item">
                                              <Link
                                                to={
                                                  childItem.link
                                                    ? childItem.link
                                                    : "/#"
                                                }
                                                className="nav-link"
                                              >
                                                {childItem.label}
                                              </Link>
                                            </li>
                                          ) : (
                                            <li className="nav-item">
                                              <Link
                                                to="/#"
                                                className="nav-link"
                                                onClick={childItem.click}
                                                data-bs-toggle="collapse"
                                              >
                                                {childItem.label}
                                              </Link>
                                              <Collapse
                                                className="menu-dropdown"
                                                isOpen={
                                                  childItem.stateVariables
                                                }
                                                id="sidebaremailTemplates"
                                              >
                                                <ul className="nav nav-sm flex-column">
                                                  {childItem.childItems.map(
                                                    (
                                                      subChildItem: any,
                                                      key: any
                                                    ) => (
                                                      <li
                                                        className="nav-item"
                                                        key={key}
                                                      >
                                                        <Link
                                                          to={subChildItem.link}
                                                          className="nav-link"
                                                          data-key="t-basic-action"
                                                        >
                                                          {subChildItem.label}{" "}
                                                        </Link>
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              </Collapse>
                                            </li>
                                          )}
                                        </React.Fragment>
                                      )
                                    )}
                                </ul>
                              </Collapse>
                            </li>
                          )}
                        </React.Fragment>
                      ))}
                  </ul>
                </Collapse>
              </li>
            ) : (
              <>
                <li className="nav-item">
                  <Link
                    className="nav-link menu-link"
                    to={item.link ? item.link : "/#"}
                  >
                    <img src={item.icon} alt="icon" />
                  </Link>
                </li>
                {item.divider ? <div className="divider"></div> : null}
              </>
            )}
          </React.Fragment>
        );
      })}
      <div
        className="new-chat-sidebar"
        onClick={() => {
          setChatSessionId("");
          setChatTypeStatus(ChatType(true));
          setChatModalSubmitStatus(false);
          setPromptCardStatus(true);
          navigate("/chat");
        }}
      >
        <img src={NewChatIcon} alt="new" />
      </div>
    </React.Fragment>
  );
};

export default withRouter(VerticalLayout);
