import { useNavigate } from "react-router-dom";
import withRouter from "../../Common/Components/withRouter";
import { useEffect } from "react";

const NonAuthLayout = ({ children }: any) => {
  const navigate = useNavigate();

  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchProfile() {
    const authTokenString = window.sessionStorage.getItem("token");

    if (authTokenString) {
      navigate("/dashboard");
    }
  }

  return <div>{children}</div>;
};

export default withRouter(NonAuthLayout);
