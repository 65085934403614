import React from "react";
import "./assets/scss/themes.scss";
import Route from "./Routes";
import { Toaster } from "react-hot-toast";
import { Store } from "Store";
import { LoadingSpinner } from "Common/Components/Loader/Loader";

function App() {
  const { isLoading } = Store.User();
  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner />}
      <Toaster position="top-center" />
      <Route />
    </React.Fragment>
  );
}

export default App;
