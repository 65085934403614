import AddIconDark from "../../../../assets/images/Icon/AddIconDark.svg";
import FilterIcon from "../../../../assets/images/Icon/FilterIcon.svg";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledDropdown,
} from "reactstrap";
import { useEffect, useState } from "react";
import {
  isToday,
  isYesterday,
  isWithinInterval,
  startOfDay,
  subDays,
  startOfMonth,
  format,
} from "date-fns";
import MessageList from "../MessageList/MessageList";
import { chatSessionPayload, ChatSideBarProps } from "Common/Interface";
import { Store } from "Store";
import ChatServiceObj from "API/chatService";
import { useNavigate } from "react-router-dom";
import { ChatType } from "Common/helper/helper";
import { LoadingSpinner } from "Common/Components/Loader/Loader";
import { CHAT_TYPE, SESSION_UPDATE_TYPE } from "Constant/type";
import toast from "react-hot-toast";

const ChatSideBar = (props: ChatSideBarProps) => {
  const { showStatus } = props;
  const [historyChats, setHistoryChats] = useState<any[]>([]);
  // const [favoriteChats, setFavoriteChats] = useState<any[]>([]);
  const [todayChats, setTodayChats] = useState<any[]>([]);
  const [yesterdayChats, setYesterdayChats] = useState<any[]>([]);
  const [previous7DaysChats, setPrevious7DaysChats] = useState<any[]>([]);
  const [previous30DaysChats, setPrevious30DaysChats] = useState<any[]>([]);
  // const [currentMonthChats, setCurrentMonthChats] = useState<any[]>([]);
  const [previousMonthsChats, setPreviousMonthsChats] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(50);
  const [loadingData, setLoadingData] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [isFilterApply, setIsFilterApply] = useState(false);
  // const [isOwnCheck, setIsOwnCheck] = useState(false);
  const [isPrivateChat, setIsPrivateChat] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const {
    setPromptCardStatus,
    initialChatData,
    setChatSessionId,
    setInitialChatStatus,
    chatSessionId,
    isChatTypeChange,
    setChangeChatType,
    removedHistoryData,
    setRemovedHistoryData,
    chatTypeStatus,
    setChatTypeStatus,
    setChatModalSubmitStatus,
  } = Store.Chat((state) => ({
    setPromptCardStatus: state.setPromptCardStatus,
    initialChatData: state.initialChatData,
    setChatSessionId: state.setChatSessionId,
    setInitialChatStatus: state.setInitialChatStatus,
    chatSessionId: state.chatSessionId,
    isChatTypeChange: state.isChatTypeChange,
    setChangeChatType: state.setChangeChatType,
    removedHistoryData: state.removedHistoryData,
    setRemovedHistoryData: state.setRemovedHistoryData,
    chatTypeStatus: state.chatTypeStatus,
    setChatTypeStatus: state.setChatTypeStatus,
    setChatModalSubmitStatus: state.setChatModalSubmitStatus,
  }));

  useEffect(() => {
    const containerDiv = document.getElementById("chat-history");
    const handleScroll = () => {
      if (containerDiv) {
        if (
          containerDiv.scrollTop + containerDiv.clientHeight >=
            containerDiv.scrollHeight - 20 &&
          !loadingData &&
          limit * page < totalCount
        ) {
          setPage((pervPage) => pervPage + 1);
          setIsFilterApply(false);
        }
      }
    };

    containerDiv && containerDiv.addEventListener("scroll", handleScroll);

    return () => {
      containerDiv && containerDiv.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingData]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, isPrivateChat]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if ((search.length === 0 || search.length > 2) && isFilterApply) {
        fetchData();
      }
    }, 800);

    return () => clearTimeout(getData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, isFilterApply]);

  useEffect(() => {
    if (initialChatData) {
      if (
        (isPrivateChat && Number(chatTypeStatus) === CHAT_TYPE.PRIVATE) ||
        (!isPrivateChat && Number(chatTypeStatus) === CHAT_TYPE.PUBLIC)
      ) {
        addTodaysChat(initialChatData);
      } else {
        addDataToTemp(initialChatData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialChatData]);

  useEffect(() => {
    if (isChatTypeChange) {
      setChangeChatType(false);
      removeChat(chatSessionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatTypeChange]);

  const removeChat = (chatSessionId: string) => {
    const tempRemovedData: any[] = [];
    let updatedChat: any[] = [];
    if (Number(chatTypeStatus) === CHAT_TYPE.PRIVATE) {
      const removedData = historyChats.filter((_item) => {
        return _item.id === chatSessionId;
      })[0];

      tempRemovedData.push(removedData, ...removedHistoryData);
      setRemovedHistoryData(tempRemovedData);

      updatedChat = historyChats.filter((_item) => {
        return _item.id !== chatSessionId;
      });
    }
    if (Number(chatTypeStatus) === CHAT_TYPE.PUBLIC) {
      const removedDataObj = removedHistoryData.filter((_item: any) => {
        return _item.id !== chatSessionId;
      });

      setRemovedHistoryData(removedDataObj);

      const removedData = removedHistoryData.filter((_item: any) => {
        return _item.id === chatSessionId;
      })[0];
      if (removedData) {
        updatedChat.push(removedData, ...historyChats);
      }
    }

    const todayChats =
      updatedChat.filter(
        (_item: { created_at: string | number | Date; favorite: boolean }) =>
          isToday(new Date(_item.created_at))
      ) || [];
    const yesterdayChats =
      updatedChat.filter(
        (_item: { created_at: string | number | Date; favorite: boolean }) =>
          isYesterday(new Date(_item.created_at))
      ) || [];

    const previous7DaysChats =
      updatedChat.filter((prompt: { created_at: string | number | Date }) =>
        isWithinLastNDays(new Date(prompt.created_at), 7, 2)
      ) || [];

    const previous30DaysChats =
      updatedChat.filter((prompt: { created_at: string | number | Date }) =>
        isWithinLastNDays(new Date(prompt.created_at), 30, 7)
      ) || [];

    const previousMonthsChats =
      updatedChat.filter((prompt: { created_at: string | number | Date }) =>
        isPreviousMonths(new Date(prompt.created_at), 30)
      ) || [];

    setTodayChats(todayChats);
    setYesterdayChats(yesterdayChats);
    setPrevious7DaysChats(previous7DaysChats);
    setPrevious30DaysChats(previous30DaysChats);
    setPreviousMonthsChats(previousMonthsChats);
    setHistoryChats(updatedChat);
  };

  const addDataToTemp = (initialChatData: any) => {
    const tempArray = [];
    const tempObj = {
      id: initialChatData?.id,
      title: initialChatData?.msg,
      created_at: new Date().toISOString(),
      favorite: false,
    };
    tempArray.push(tempObj, ...removedHistoryData);
    setRemovedHistoryData(tempArray);
  };

  const addTodaysChat = (initialChatData: any) => {
    if (initialChatData && initialChatData?.id && initialChatData?.msg) {
      const index = todayChats.findIndex(
        (_list) => _list.id === initialChatData?.id
      );
      if (index === -1) {
        const tempTodayArray = [];
        const tempArray = [];
        const tempObj = {
          id: initialChatData?.id,
          title: initialChatData?.msg,
          created_at: new Date().toISOString(),
          favorite: false,
        };
        tempTodayArray.push(tempObj, ...todayChats);
        tempArray.push(tempObj, ...historyChats);
        setTodayChats(tempTodayArray);
        setHistoryChats(tempArray);
      }
    }
  };

  const fetchData = async () => {
    try {
      // Fetch history Chats
      setLoadingData(true);
      setIsLoading(true);
      const sessionPayload: any = {
        per_page: limit,
        page: page,
        public: !isPrivateChat,
      };
      if (search) {
        sessionPayload.title = search;
      }
      const sessionsResponse = await ChatServiceObj.getSessions(sessionPayload);

      const fetchedHistoryChats = sessionsResponse?.data?.chat_sessions || [];
      setTotalCount(sessionsResponse?.data?.total_chat_sessions);

      const todayChats =
        fetchedHistoryChats.filter(
          (_item: { created_at: string | number | Date; favorite: boolean }) =>
            isToday(new Date(_item.created_at))
        ) || [];
      const yesterdayChats =
        fetchedHistoryChats.filter(
          (_item: { created_at: string | number | Date; favorite: boolean }) =>
            isYesterday(new Date(_item.created_at))
        ) || [];

      const previous7DaysChats =
        fetchedHistoryChats.filter(
          (prompt: { created_at: string | number | Date }) =>
            isWithinLastNDays(new Date(prompt.created_at), 7, 2)
        ) || [];

      const previous30DaysChats =
        fetchedHistoryChats.filter(
          (prompt: { created_at: string | number | Date }) =>
            isWithinLastNDays(new Date(prompt.created_at), 30, 7)
        ) || [];
      // const currentMonthChats = fetchedHistoryChats.filter(
      //   (prompt: { created_at: string | number | Date }) =>
      //     isCurrentMonth(new Date(prompt.created_at))
      // );
      const previousMonthsChats =
        fetchedHistoryChats.filter(
          (prompt: { created_at: string | number | Date }) =>
            isPreviousMonths(new Date(prompt.created_at), 30)
        ) || [];
      // const fetchedFavoriteChats = fetchedHistoryChats.filter((_item: any) => {
      //   return _item.favorite === true;
      // });

      if (isFilterApply) {
        setTodayChats(todayChats);
        setYesterdayChats(yesterdayChats);
        setPrevious7DaysChats(previous7DaysChats);
        setPrevious30DaysChats(previous30DaysChats);
        // setCurrentMonthChats(currentMonthChats);
        setPreviousMonthsChats(previousMonthsChats);
        setHistoryChats(fetchedHistoryChats);
        setIsFilterApply(false);
      } else {
        setTodayChats((pervChat) => [...pervChat, ...todayChats]);
        setYesterdayChats((pervChat) => [...pervChat, ...yesterdayChats]);
        setPrevious7DaysChats((pervChat) => [
          ...pervChat,
          ...previous7DaysChats,
        ]);
        setPrevious30DaysChats((pervChat) => [
          ...pervChat,
          ...previous30DaysChats,
        ]);
        // setCurrentMonthChats((pervChat) => [...pervChat, ...currentMonthChats]);
        setPreviousMonthsChats((pervChat) => [
          ...pervChat,
          ...previousMonthsChats,
        ]);
        setHistoryChats((pervChat) => [...pervChat, ...fetchedHistoryChats]);
      }
      setLoadingData(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching Chats:", error);
    }
  };

  const isWithinLastNDays = (date: Date, days: number, subDay: number) => {
    const today = new Date();
    const startDate = subDays(startOfDay(today), days);
    const endDate = subDays(startOfDay(today), subDay);
    return isWithinInterval(startOfDay(date), {
      start: startDate,
      end: endDate,
    });
  };

  // const isCurrentMonth = (date: Date) => {
  //   const today = new Date();
  //   const startDate = startOfMonth(today);
  //   const endDate = endOfMonth(today);
  //   return isWithinInterval(startOfDay(date), {
  //     start: startDate,
  //     end: endDate,
  //   });
  // };

  const isPreviousMonths = (date: Date, days: number) => {
    const today = new Date();
    const endDate = subDays(startOfDay(today), days);
    const startDate = startOfMonth(endDate);
    return isWithinInterval(date, { start: startDate, end: endDate });
  };

  const handleChatsHistoryClick = async (id: string, type: boolean) => {
    setInitialChatStatus(false);
    setChatTypeStatus(ChatType(type));
    navigate(`/chat/${ChatType(type)}/${id}`);
  };

  const handleSearchChange = (value: string) => {
    setSearch(value);
    setIsFilterApply(true);
    setPage(1);
  };

  const handleNewChat = () => {
    setChatTypeStatus(ChatType(true));
    setChatModalSubmitStatus(false);
    setPromptCardStatus(true);
    setChatSessionId("");
    navigate("/chat");
  };

  const updateMessageValue = async (
    selectedId: string,
    value: any,
    type: string
  ) => {
    try {
      const chatSessionPayload: chatSessionPayload = {
        id: selectedId,
      };
      if (type === SESSION_UPDATE_TYPE.NAME) {
        chatSessionPayload.title = value;
      }
      if (type === SESSION_UPDATE_TYPE.FAVORITE) {
        chatSessionPayload.favorite = value;
      }
      await ChatServiceObj.updateChatSession(chatSessionPayload);
      if (type === SESSION_UPDATE_TYPE.FAVORITE && value) {
        toast.success("Added to favorite!");
      }
      if (type === SESSION_UPDATE_TYPE.FAVORITE && !value) {
        toast.success("Removed from favorite!");
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleListUpdate = async (
    id: string,
    type: string,
    value: any,
    state: any[],
    updateState: any
  ) => {
    if (type === SESSION_UPDATE_TYPE.FAVORITE) setIsLoading(true);
    await updateMessageValue(id, value, type);
    const updatedArray = state.map((obj) => {
      if (obj.id === id) {
        if (type === SESSION_UPDATE_TYPE.FAVORITE) {
          return { ...obj, favorite: value };
        }
        if (type === SESSION_UPDATE_TYPE.NAME) {
          return { ...obj, title: value };
        }
      }
      return obj;
    });
    updateState(updatedArray);
    if (type === SESSION_UPDATE_TYPE.FAVORITE) setIsLoading(false);
    if (search && search.length > 2) {
      setIsFilterApply(true);
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className={`${!showStatus ? "chat-sidebar" : "chat-sidebar hide"}`}>
        <div className="new-chat" onClick={handleNewChat}>
          <div className="left">
            <img src={AddIconDark} alt="new" />
            <div className="text">New Chat</div>
          </div>
          <div className="right">
            <div className="action-icon">STRG</div>
            <div className="action-icon">K</div>
          </div>
        </div>
        <div className="history">
          <div className="search">
            <h4>History</h4>
            <div className="search-inner">
              <Input
                type="text"
                className="form-control"
                placeholder="Search in history"
                autoComplete="off"
                id="search-options"
                onChange={(e) => handleSearchChange(e.target.value)}
              />
              <UncontrolledDropdown>
                <DropdownToggle
                  tag="button"
                  className="btn custom-filter-icon"
                  id="dropdownMenuButton"
                >
                  <img src={FilterIcon} alt="filter" />
                </DropdownToggle>
                <DropdownMenu>
                  {/* <DropdownItem>
                    <Input
                      className="form-check-input me-1"
                      type="checkbox"
                      checked={isOwnCheck}
                      onChange={() => {
                        setIsOwnCheck(!isOwnCheck);
                        setIsFilterApply(true);
                      }}
                    />
                    Own
                  </DropdownItem> */}
                  {/* <DropdownItem>
                  <Input
                    className="form-check-input me-1"
                    type="checkbox"
                    value="shared-chats"
                  />
                  Shared chats
                </DropdownItem>
                <DropdownItem>
                  <Input
                    className="form-check-input me-1"
                    type="checkbox"
                    value="shared-to-you"
                  />
                  Chats shared to you
                </DropdownItem> */}
                  <DropdownItem>
                    <Input
                      className="form-check-input me-1"
                      type="checkbox"
                      checked={isPrivateChat}
                      onChange={() => {
                        setIsPrivateChat(!isPrivateChat);
                        setIsFilterApply(true);
                      }}
                    />
                    Private chats
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
          <div id="chat-history">
            {todayChats && todayChats.length > 0 && (
              <>
                <div className="heading">Today</div>
                <div className="favorites">
                  {todayChats.map((_list: any, index: number) => {
                    return (
                      <MessageList
                        id={_list?.id}
                        key={`todayChats-${index}`}
                        title={_list?.title}
                        favorite={_list?.favorite}
                        chatType={_list?.public}
                        onClick={(id, chatType) => {
                          handleChatsHistoryClick(id, chatType);
                        }}
                        onUpdate={(id, type, value) =>
                          handleListUpdate(
                            id,
                            type,
                            value,
                            todayChats,
                            setTodayChats
                          )
                        }
                      />
                    );
                  })}
                </div>
              </>
            )}

            {yesterdayChats && yesterdayChats.length > 0 && (
              <>
                <div className="heading">Yesterday</div>
                <div className="favorites">
                  {yesterdayChats.map((_list: any, index: number) => {
                    return (
                      <MessageList
                        id={_list?.id}
                        key={`yesterdayChats-${index}`}
                        title={_list?.title}
                        favorite={_list?.favorite}
                        chatType={_list?.public}
                        onClick={(id, chatType) => {
                          handleChatsHistoryClick(id, chatType);
                        }}
                        onUpdate={(id, type, value) =>
                          handleListUpdate(
                            id,
                            type,
                            value,
                            yesterdayChats,
                            setYesterdayChats
                          )
                        }
                      />
                    );
                  })}
                </div>
              </>
            )}

            {previous7DaysChats && previous7DaysChats.length > 0 && (
              <>
                <div className="heading">Pervious 7 days</div>
                <div className="favorites">
                  {previous7DaysChats.map((_list: any, index: number) => {
                    return (
                      <MessageList
                        id={_list?.id}
                        key={`previous7DaysChats-${index}`}
                        title={_list?.title}
                        favorite={_list?.favorite}
                        chatType={_list?.public}
                        onClick={(id, chatType) => {
                          handleChatsHistoryClick(id, chatType);
                        }}
                        onUpdate={(id, type, value) =>
                          handleListUpdate(
                            id,
                            type,
                            value,
                            previous7DaysChats,
                            setPrevious7DaysChats
                          )
                        }
                      />
                    );
                  })}
                </div>
              </>
            )}

            {previous30DaysChats && previous30DaysChats.length > 0 && (
              <>
                <div className="heading">Pervious 30 days</div>
                <div className="favorites">
                  {previous30DaysChats.map((_list: any, index: number) => {
                    return (
                      <MessageList
                        id={_list?.id}
                        key={`previous30DaysChats-${index}`}
                        title={_list?.title}
                        favorite={_list?.favorite}
                        chatType={_list?.public}
                        onClick={(id, chatType) => {
                          handleChatsHistoryClick(id, chatType);
                        }}
                        onUpdate={(id, type, value) =>
                          handleListUpdate(
                            id,
                            type,
                            value,
                            previous30DaysChats,
                            setPrevious30DaysChats
                          )
                        }
                      />
                    );
                  })}
                </div>
              </>
            )}

            {previousMonthsChats && previousMonthsChats.length > 0 && (
              <>
                <div className="heading">
                  {format(previousMonthsChats[0].created_at, "MMMM")}
                </div>
                <div className="favorites">
                  {previousMonthsChats.map((_list: any, index: number) => {
                    return (
                      <MessageList
                        id={_list?.id}
                        key={`previousMonthsChats-${index}`}
                        title={_list?.title}
                        favorite={_list?.favorite}
                        chatType={_list?.public}
                        onClick={(id, chatType) => {
                          handleChatsHistoryClick(id, chatType);
                        }}
                        onUpdate={(id, type, value) =>
                          handleListUpdate(
                            id,
                            type,
                            value,
                            previousMonthsChats,
                            setPreviousMonthsChats
                          )
                        }
                      />
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatSideBar;
