import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import animationData from "assets/images/LoginAnimation.json";
import { Form, Spinner, Input, Button } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import withRouter from "../../../../src/Common/Components/withRouter";
import loginLogo from "assets/images/Icon/LoginLogo.svg";
import { CONFIG } from "Constant/config";
import UserService from "API/userService";
import { signIn } from "Common/helper/helper";
import { newWindowPopup } from "Common/Components/NativePopup/nativePopup";
import toast from "react-hot-toast";
import { API_URL } from "Constant/Environment";

const LogIn = (): JSX.Element => {
  const [loader] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!CONFIG.loginWithPassword) {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get("token");
      if (token && token !== "") {
        signIn(token, "name", "email");
        navigate("/dashboard");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const validation = useFormik({
    enableReinitialize: false,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await UserService.login(values.email, values.password);
        if (response.status === 200) {
          const { token, name, email } = response.data;
          signIn(token, name, email);
          navigate("/dashboard");
        } else {
          toast.error(response.data.message);
        }
      } catch (err: any) {
        console.error(err);
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.response.data.message);
        }
      }
    },
  });

  const login = async () => {
    console.log("login with Azure AD");
    await newWindowPopup(
      `${API_URL}/sso/login`,
      "login with Azure AD",
      500,
      500
    );
  };

  return (
    <div className="log-in">
      {/* Left Content */}
      <div className="left">
        <div className="lottie-container d-flex ">
          <Lottie
            animationData={{ ...animationData, fr: 100 }}
            autoplay={true}
            loop={true}
            style={{ width: "70%", height: "70%" }} // Adjust size as needed
          />
        </div>
      </div>
      {/* Right Content */}
      <div className="right">
        <div className="content">
          <div className="SSO-login-logo">
            <img src={loginLogo} alt="login-logo" />
          </div>
          <div className="login-form">
            <div className="text mb-2">
              <div className="text">
                <div className="text-wrapper-3">Welcome back</div>
                {!CONFIG.loginWithPassword && (
                  <div className="text-wrapper-4">Sign in with Microsoft.</div>
                )}
              </div>
            </div>
            {/* Form Section */}
            {CONFIG.loginWithPassword && (
              <div className="form">
                <Form onSubmit={validation.handleSubmit} action="#">
                  <div className="input-2">
                    <div className="frame-3">
                      <Input
                        className={`design-component-instance-node ${
                          validation.touched.email && validation.errors.email
                            ? "is-invalid"
                            : ""
                        }`}
                        type="text"
                        placeholder="Email"
                        name="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                      />
                      {validation.touched.email && validation.errors.email && (
                        <div className="invalid-feedback">
                          {validation.errors.email}
                        </div>
                      )}
                      <Input
                        className={`design-component-instance-node ${
                          validation.touched.password &&
                          validation.errors.password
                            ? "is-invalid"
                            : ""
                        }`}
                        type="password"
                        placeholder="Password"
                        name="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ""}
                      />
                      {validation.touched.password &&
                        validation.errors.password && (
                          <div className="invalid-feedback">
                            {validation.errors.password}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="mt-1">
                    <Button
                      style={{ color: "#007BC4" }}
                      disabled={loader && true}
                      className="btn btn-blue w-100"
                      type="submit"
                    >
                      {loader && (
                        <Spinner size="sm" className="me-2">
                          {" "}
                          Loading...{" "}
                        </Spinner>
                      )}
                      <span style={{ color: "white" }}>Log in</span>
                    </Button>
                  </div>
                </Form>
              </div>
            )}
            {/* Buttons */}
            {!CONFIG.loginWithPassword && (
              <div className="buttons btn p-0" style={{ cursor: "pointer" }}>
                <div className="SSO-login-instance">
                  <div className="SSO-login">
                    <img
                      className="SSO-icon"
                      alt="Sso icon"
                      src="https://c.animaapp.com/MuEnNtt0/img/sso-icon-3.svg"
                    />
                    <div className="label" onClick={() => login()}>
                      Sign in with Microsoft
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(LogIn);
