import { ChatScreenProps } from "Common/Interface";
import ChatMessage from "../Message/Message";
import MessageCard from "../MessageCard/MessageCard";
import MessageResponseCard from "../MessageCard/MessageResponseCard";
import { useEffect, useState } from "react";
import ChatService from "API/chatService";
import GenerateResponseCard from "../MessageCard/GenerateCard";
import { Store } from "Store";
import { CHAT_ACTION, CHAT_TYPE, MESSAGE_TYPE } from "Constant/type";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingSpinner } from "Common/Components/Loader/Loader";
import toast from "react-hot-toast";

const ChatComponent = (props: ChatScreenProps) => {
  const { showStatus } = props;
  const [chatData, setChatData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { sessionId, chatType } = useParams();
  const navigate = useNavigate();

  const {
    chatPromptMsg,
    chatPromptStatus,
    chatModalSubmitStatus,
    setChatPromptMsg,
    setChatSessionId,
    setInitialChatData,
    chatSessionId,
    initialChatStatus,
    setInitialChatStatus,
    setChatCancelToken,
    chatCancelToken,
    chatTypeStatus,
    isGenerating,
    setIsGenerating,
  } = Store.Chat((state: any) => ({
    chatPromptMsg: state.chatPromptMsg,
    chatPromptStatus: state.chatPromptStatus,
    chatModalSubmitStatus: state.chatModalSubmitStatus,
    setChatPromptMsg: state.setChatPromptMsg,
    setChatSessionId: state.setChatSessionId,
    setInitialChatData: state.setInitialChatData,
    chatSessionId: state.chatSessionId,
    initialChatStatus: state.initialChatStatus,
    setInitialChatStatus: state.setInitialChatStatus,
    setChatCancelToken: state.setChatCancelToken,
    chatCancelToken: state.chatCancelToken,
    chatTypeStatus: state.chatTypeStatus,
    isGenerating: state.isGenerating,
    setIsGenerating: state.setIsGenerating,
  }));

  useEffect(() => {
    if (chatPromptMsg && (!chatPromptStatus || chatModalSubmitStatus)) {
      setChatData([]);
      setChatSessionId("");
      chatCancelToken?.abort();
      callApi();
    }
    return () => {
      chatCancelToken?.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatPromptMsg, chatPromptStatus, chatModalSubmitStatus]);

  useEffect(() => {
    if (sessionId && !initialChatStatus) {
      setIsGenerating(false);
      setChatData([]);
      chatCancelToken?.abort();
      callHistoryDataApi(sessionId);
    }
    return () => {
      chatCancelToken?.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, initialChatStatus]);

  const callHistoryDataApi = async (sessionId: string) => {
    setIsLoading(true);
    await getChatHistoryData(sessionId);
    setIsLoading(false);
  };

  const getChatHistoryData = async (id: string) => {
    try {
      const historyResponse = await ChatService.getHistory(id);
      setChatSessionId(id);
      const fetchedHistoryChats = historyResponse?.data?.chat_history || [];
      const convertedData: any = convertData(fetchedHistoryChats);
      setChatData(convertedData);
    } catch (err) {
      console.log(err);
    }
  };

  const convertData = (data: string[]) => {
    if (data && data.length > 0) {
      let convertedData: any[] = [];
      data.sort(
        (a: any, b: any) =>
          new Date(a?.created_at).getTime() - new Date(b?.created_at).getTime()
      );
      data.forEach((item: any) => {
        convertedData.push({
          id: item?.id,
          type: MESSAGE_TYPE.QUESTION,
          message: item.question,
        });
        convertedData.push({
          id: item?.id,
          type: MESSAGE_TYPE.ANSWER,
          response: item?.answer,
          action: item?.action,
          chat_id: item?.id,
          chat_session_id: sessionId,
        });
      });

      return convertedData;
    }
  };

  const callApi = async () => {
    await handleChatSubmit(chatPromptMsg);
    if (chatPromptMsg) {
      setChatPromptMsg("");
    }
  };

  useEffect(() => {
    getActiveDiv();
  }, [chatData]);

  const getActiveDiv = () => {
    const elementList: any = document.querySelectorAll(".message-card");
    for (const element of elementList) {
      if (element.classList.contains("active")) {
        const containerLeft: any =
          document.getElementById("message-card-outer");
        containerLeft.scrollTop = element.offsetTop - containerLeft.offsetTop;
        break;
      }
    }
  };

  const addChat = (msg: string) => {
    const temp = {
      id: 1,
      message: msg,
      type: MESSAGE_TYPE.QUESTION,
    };
    setChatData((prevChat) => [...prevChat, { ...temp }]);
  };

  const handleChatSubmit = async (msg: string) => {
    if (!isGenerating) {
      addChat(msg);
      setIsGenerating(true);
      await getChatResponse(msg);
      setIsGenerating(false);
    }
  };

  const getChatResponse = async (msg: string) => {
    try {
      const abortController = new AbortController();
      setChatCancelToken(abortController);
      const payload: any = {
        question: msg,
      };
      if (chatSessionId) {
        payload.chat_session_id = chatSessionId;
      }
      const { data } = await ChatService.chat(payload, abortController.signal);
      setInitialChatStatus(true);

      setChatSessionId(data.chat_session_id);
      const tempMsg = {
        ...data,
        type: MESSAGE_TYPE.ANSWER,
        action: CHAT_ACTION.UNDO,
      };
      if (!chatSessionId) {
        setInitialChatData(data.chat_session_id, msg);
        changeChatType(data.chat_session_id);
      }
      if (chatType) {
        navigate(`/chat/${chatType}/${data.chat_session_id}`);
      } else if (chatTypeStatus && chatTypeStatus === CHAT_TYPE.PRIVATE) {
        navigate(`/chat/${CHAT_TYPE.PRIVATE}/${data.chat_session_id}`);
      } else {
        navigate(`/chat/${CHAT_TYPE.PUBLIC}/${data.chat_session_id}`);
      }
      setChatData((prevChat) => [...prevChat, { ...tempMsg }]);
    } catch (err: any) {
      console.log(err);
    }
  };

  const changeChatType = async (sessionId: string) => {
    if (sessionId) {
      try {
        const type = chatTypeStatus === CHAT_TYPE.PUBLIC ? true : false;
        await ChatService.updateChatSession({
          id: sessionId,
          public: type,
        });
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  const handleAction = async (
    chatId: string,
    chatSessionId: string,
    action: number
  ) => {
    setIsLoading(true);
    await chatActionApi(chatId, chatSessionId, action);
    const updatedChat = chatData.map((_item: any) => {
      if (
        _item?.chat_id === chatId &&
        _item?.chat_session_id === chatSessionId &&
        _item.type === MESSAGE_TYPE.ANSWER
      ) {
        return { ..._item, action: action };
      }
      return _item;
    });
    if (action === CHAT_ACTION.LIKE) {
      toast.success("Like", { icon: "👍" });
    } else if (action === CHAT_ACTION.DISLIKE) {
      toast.success("Dislike", { icon: "👎" });
    }
    setChatData(updatedChat);
    setIsLoading(false);
  };

  const chatActionApi = async (
    chatId: string,
    chatSessionId: string,
    action: number
  ) => {
    try {
      const payload: any = {
        chat_id: chatId,
        chat_session_id: chatSessionId,
        action: action,
      };
      await ChatService.chatAction(payload);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className={`chat-section ${!showStatus ? "left" : ""}`}>
        <div className="message-card-outer" id="message-card-outer">
          {chatData &&
            chatData.length > 0 &&
            chatData.map((_data: any, index: number) => {
              return (
                <div key={index}>
                  {_data.type === MESSAGE_TYPE.QUESTION ? (
                    <MessageCard
                      msg={_data.message}
                      activeStatus={
                        chatData.length - 1 === index ? true : false
                      }
                    />
                  ) : (
                    <MessageResponseCard
                      msg={_data?.response}
                      action={_data?.action}
                      chatId={_data?.chat_id}
                      chatSessionId={_data?.chat_session_id}
                      activeStatus={
                        chatData.length - 1 === index ? true : false
                      }
                      handleAction={(chatId, chatSessionId, action) =>
                        handleAction(chatId, chatSessionId, action)
                      }
                    />
                  )}
                </div>
              );
            })}
          {isGenerating && <GenerateResponseCard />}
        </div>
        <ChatMessage onSubmit={handleChatSubmit} />
      </div>
    </>
  );
};

export default ChatComponent;
