import { Store } from "Store";
import ChatMessage from "../Message/Message";
import { useNavigate } from "react-router-dom";

const ChatModal = () => {
  const {
    setChatModalStatus,
    chatModalStatus,
    setChatPromptMsg,
    setChatModalSubmitStatus,
    setChatSessionId,
    setPromptCardStatus,
  } = Store.Chat();

  const navigate = useNavigate();

  const togglePopup = () => {
    setChatModalStatus(!chatModalStatus);
  };

  const handleSubmit = (msg: string) => {
    setChatPromptMsg(msg);
    setChatSessionId("");
    setPromptCardStatus(false);
    setChatModalSubmitStatus(true);
    setChatModalStatus(!chatModalStatus);
    navigate("/chat");
  };

  return (
    <div className="chat-modal">
      <div className="overlay" onClick={togglePopup}></div>
      <div className="popup">
        <ChatMessage onSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default ChatModal;
