import { MessageListProps } from "Common/Interface";
import MoreIcon from "../../../../assets/images/Icon/MoreIcon.svg";
import StarIcon from "../../../../assets/images/Icon/StartIcon.svg";
import StarFillIcon from "../../../../assets/images/Icon/StarFill.svg";
import RenameIcon from "../../../../assets/images/Icon/RenameIcon.svg";
// import ShareIcon from "../../../../assets/images/Icon/ShareIcon.svg";
// import DeleteIcon from "../../../../assets/images/Icon/DeleteIcon.svg";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledDropdown,
} from "reactstrap";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { SESSION_UPDATE_TYPE } from "Constant/type";

const MessageList = (props: MessageListProps) => {
  const { title, id, favorite, onClick, onUpdate, chatType } = props;
  const { sessionId } = useParams();
  const [selectedId, setSelectedId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [showInputBox, setShowInputBox] = useState(false);

  const handleBlur = () => {
    setShowInputBox(false);
    onUpdate(selectedId, SESSION_UPDATE_TYPE.NAME, selectedTitle);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      setShowInputBox(false);
      onUpdate(selectedId, SESSION_UPDATE_TYPE.NAME, selectedTitle);
    }
  };

  const handleFavorite = (id: string, favorite: boolean) => {
    onUpdate(id, SESSION_UPDATE_TYPE.FAVORITE, favorite);
  };

  const handleRename = (id: string, title: string) => {
    setShowInputBox(true);
    setSelectedId(id);
    setSelectedTitle(title);
  };

  return (
    <div
      className={`message-list ${
        sessionId && sessionId === id ? "active" : ""
      }`}
    >
      {showInputBox && selectedId === id ? (
        <Input
          onBlur={handleBlur}
          type="text"
          value={selectedTitle}
          onKeyDown={handleKeyPress}
          onChange={(e) => setSelectedTitle(e.target.value)}
        />
      ) : (
        <div className="text" onClick={() => onClick(id, chatType)}>
          {title}
        </div>
      )}

      <div>
        {favorite ? (
          <img
            src={StarFillIcon}
            className="cursor-pointer"
            alt="star"
            onClick={() => handleFavorite(id, !favorite)}
          />
        ) : (
          <img
            src={StarIcon}
            className="cursor-pointer"
            alt="star-fill"
            onClick={() => handleFavorite(id, !favorite)}
          />
        )}
      </div>
      <li className="list-inline-item">
        <UncontrolledDropdown>
          <DropdownToggle href="#" className="btn btn-sm dropdown" tag="button">
            <img src={MoreIcon} alt="more" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem
              className="dropdown-item edit-item-btn"
              onClick={() => handleRename(id, title)}
            >
              <img alt="rename" src={RenameIcon} className="mx-2" />
              Rename
            </DropdownItem>

            {/* <DropdownItem className="dropdown-item" href="#">
              <img alt="share" src={ShareIcon} className="mx-2" />
              Share
            </DropdownItem>
            <DropdownItem className="dropdown-item delete-icon">
              <img alt="delete" src={DeleteIcon} className="mx-2" />
              Delete
            </DropdownItem> */}
          </DropdownMenu>
        </UncontrolledDropdown>
      </li>
    </div>
  );
};

export default MessageList;
