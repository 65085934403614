import BaseApiService from "./baseService";

class UserService extends BaseApiService {
  constructor() {
    super("users");
  }

  login(email: string, password: string) {
    return this.post("login", { email, password });
  }

  currentUser(param: any) {
    return this.get("current-user", param);
  }
}

const objUserService = new UserService();
export default objUserService;
