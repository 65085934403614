import PromptCard from "../PromptCard.tsx/PromptCard";
import ChatMessage from "../Message/Message";
import { PromptProps, PromptsCardProps } from "Common/Interface";
import { useEffect, useState } from "react";
import ChatServiceObj from "API/chatService";
import { Store } from "Store";
import { LoadingSpinner } from "Common/Components/Loader/Loader";

const Prompt = (props: PromptProps) => {
  const { showStatus } = props;
  const [promptsData, setPromptsData] = useState<PromptsCardProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { chatPromptStatus, setChatPromptMsg, setPromptCardStatus } =
    Store.Chat();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await ChatServiceObj.getPrompts({ limit: 6 });
      if (response.data && Array.isArray(response.data.prompts)) {
        setPromptsData(response.data.prompts);
      } else {
        console.error("Invalid data format in the response:", response);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching prompts:", error);
      setIsLoading(false);
    }
  };

  const handlePromptCard = (id: string, prompt: string) => {
    setChatPromptMsg(prompt);
    setPromptCardStatus(!chatPromptStatus);
  };

  const handleChatSubmit = (msg: string) => {
    setChatPromptMsg(msg);
    setPromptCardStatus(!chatPromptStatus);
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className={`prompt ${!showStatus ? "left" : ""}`}>
        <div className="header">
          <div className="title">What are you looking for?</div>
          <div className="subtitle">Select a prompt or type something</div>
        </div>
        <div className="cards-container">
          {promptsData.map((_data: PromptsCardProps, index: number) => {
            return (
              <PromptCard
                title={_data.title}
                description={_data.description}
                key={index}
                onClick={(id, prompt) => handlePromptCard(id, prompt)}
                id={_data.id}
                prompt={_data.prompt}
              />
            );
          })}
        </div>
        <ChatMessage onSubmit={handleChatSubmit} />
      </div>
    </>
  );
};

export default Prompt;
