import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HomeIcon from "../../assets/images/Icon/HomeIcon.svg";
import ChatIcon from "../../assets/images/Icon/ChatIcon.svg";
// import NotificationIcon from "../../assets/images/Icon/NotificationIcon.svg";

const NavData = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState<boolean>(false);
  const [isChat, setIsChat] = useState<boolean>(false);
  // const [isNotification, setIsNotification] = useState<boolean>(false);
  const [isCurrentState, setIsCurrentState] = useState("Dashboard");

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems: any = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("sub-items");
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (isCurrentState !== "Dashboard") {
      setIsDashboard(false);
    }

    if (isCurrentState !== "Chat") {
      setIsChat(false);
    }

    if (isCurrentState === "Widgets") {
      history("/widgets");
      document.body.classList.add("twocolumn-panel");
    }
    if (isCurrentState !== "Landing") {
      // setIsLanding(false);
    }
  }, [history, isCurrentState, isDashboard]);

  const menuItems: any = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboards",
      icon: HomeIcon,
      link: "/dashboard",
      stateVariables: isDashboard,
      click: function (e: any) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIsCurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "chat",
      label: "Chat",
      icon: ChatIcon,
      link: "/chat",
      stateVariables: isChat,
      divider: true,
      click: function (e: any) {
        e.preventDefault();
        setIsChat(!isChat);
        setIsCurrentState("Chat");
        updateIconSidebar(e);
      },
    },
    // {
    //   id: "notification",
    //   label: "Notification",
    //   icon: NotificationIcon,
    //   link: "/chat",
    //   stateVariables: isNotification,
    //   click: function (e: any) {
    //     e.preventDefault();
    //     setIsNotification(!isNotification);
    //     setIsCurrentState("notification");
    //     updateIconSidebar(e);
    //   },
    // },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default NavData;
