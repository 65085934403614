import { CHAT_TYPE } from "Constant/type";

export const signIn = async (token: string, name: string, email: string) => {
  window.sessionStorage.setItem("authenticated", "true");
  window.sessionStorage.setItem("token", token);
  window.sessionStorage.setItem(
    "user",
    JSON.stringify({
      name,
      email,
    })
  );
};
export const signOut = () => {
  window.sessionStorage.removeItem("authenticated");
  window.sessionStorage.removeItem("token");
  window.sessionStorage.removeItem("user");
};

export const ChatType = (type: boolean) => {
  if (type) {
    return CHAT_TYPE.PUBLIC;
  } else {
    return CHAT_TYPE.PRIVATE;
  }
};
