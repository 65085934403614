import { KpiCardProps } from "Common/Interface";
import React from "react";

const KpiCard: React.FC<KpiCardProps> = ({ title, value, description }) => {
  return (
    <div className="KPI-cards">
      <div className="div-2">
        <div className="div-4 pb-0">
          <div className="div-wrapper">
            <div className="text-wrapper-3">{title}</div>
          </div>
          <div className="div-5">
            <div className="text-wrapper-4">{value}</div>
            <div className="div-wrapper-2">
              <div className="text-wrapper-5">{description}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KpiCard;
