import { PromptsCardProps } from "Common/Interface";

const PromptCard = (props: PromptsCardProps) => {
  const { title, description, onClick, id, prompt } = props;

  const handleClick = (id: string, prompt: string) => {
    onClick(id, prompt);
  };
  return (
    <div className="prompt-card" onClick={() => handleClick(id, prompt)}>
      <div className="header">
        <div className="title">{title}</div>
        <div>
          <i className="ri-arrow-right-line icon-size"></i>
        </div>
      </div>
      <div className="description">{description}</div>
    </div>
  );
};

export default PromptCard;
