import { Input, InputGroup, Label } from "reactstrap";
import ChatIcon from "../../../../assets/images/Icon/Chat.svg";
// import FilterIcon from "../../../../assets/images/Icon/FilterIcon.svg";
import { ChatMessageProps } from "Common/Interface";
import { useEffect, useState } from "react";
import ChatService from "API/chatService";
import { useNavigate, useParams } from "react-router-dom";
import { CHAT_TYPE } from "Constant/type";
import { ChatType } from "Common/helper/helper";
import { LoadingSpinner } from "Common/Components/Loader/Loader";
import { Store } from "Store";

const ChatMessage = (props: ChatMessageProps) => {
  const { onSubmit } = props;

  const [messageValue, setMessageValue] = useState("");
  const [isPublic, setIsPublic] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { sessionId } = useParams();
  const { setChangeChatType, setChatTypeStatus, chatTypeStatus, isGenerating } =
    Store.Chat();
  const navigate = useNavigate();

  useEffect(() => {
    // if (chatTypeStatus) {
    if (Number(chatTypeStatus) === CHAT_TYPE.PUBLIC) {
      setIsPublic(true);
    } else if (Number(chatTypeStatus) === CHAT_TYPE.PRIVATE) {
      setIsPublic(false);
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatTypeStatus]);

  const handleChatType = async () => {
    if (sessionId) {
      try {
        setIsLoading(true);
        await ChatService.updateChatSession({
          id: sessionId,
          public: !isPublic,
        });
        navigate(`/chat/${ChatType(!isPublic)}/${sessionId}`);
        setIsPublic(!isPublic);
        setChatTypeStatus(ChatType(!isPublic));
        setChangeChatType(true);
        setIsLoading(false);
      } catch (err: any) {
        console.log(err);
        setIsLoading(false);
      }
    } else {
      setChatTypeStatus(ChatType(!isPublic));
      setIsPublic(!isPublic);
    }
  };

  const handleSubmit = () => {
    if (messageValue && messageValue.trim() && !isGenerating) {
      onSubmit(messageValue);
      setMessageValue("");
    }
  };

  const handleEnter = (e: any) => {
    if (e.key === "Enter" && messageValue && !isGenerating) {
      handleSubmit();
    }
  };

  const handleMessageValue = (value: string) => {
    if (value && value.trim()) {
      setMessageValue(value);
    } else {
      setMessageValue("");
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className="chat-message">
        <InputGroup>
          <Input
            autoFocus={true}
            type="text"
            className="form-control form-control-lg"
            value={messageValue}
            onKeyDown={(e) => handleEnter(e)}
            onChange={(e) => handleMessageValue(e.target.value)}
          />
          <div
            className={`submit-btn ${
              messageValue && !isGenerating ? "active" : ""
            }`}
            onClick={handleSubmit}
          >
            <img src={ChatIcon} alt="chat" />
          </div>
        </InputGroup>

        <div className="action mt-2">
          <div className="form-switch form-switch-custom form-switch-lg">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={isPublic}
              onChange={handleChatType}
            />
            <Label className="form-check-label" for="flexSwitchCheckChecked">
              {isPublic ? "Public" : "Private"}
            </Label>
          </div>
          {/* <div className="focus-outer">
          <img src={FilterIcon} alt="focus" />
          <div className="focus">Focus</div>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default ChatMessage;
