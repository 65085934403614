import { Container } from "reactstrap";
import { useState } from "react";
import { Store } from "Store";
import AddIconDark from "../../assets/images/Icon/AddIcon.svg";
import { useNavigate } from "react-router-dom";
import Trendfile from "../../assets/images/Icon/FileIcon.svg";
import KpiCard from "./Components/ConvoCards";

const Dashboard = () => {
  const navigate = useNavigate();
  const { setPromptCardStatus, setChatSessionId } = Store.Chat();
  const { currentUser } = Store.User((state: any) => ({
    currentUser: state.currentUser,
  }));

  const handleNewChat = () => {
    setPromptCardStatus(true);
    setChatSessionId("");
    navigate("/chat");
  };
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <div className="page-content">
        <Container fluid className="backgroung-main p-0">
          <div className="padding-main">
            <div className="frame">
              <div className="text-wrapper-3">
                Welcome, {currentUser?.first_name}
              </div>
              <div
                className={`new-chat ${isHovered ? "hovered" : ""}`}
                onClick={handleNewChat}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <div className="left">
                  <img className="new-chat-icon" src={AddIconDark} alt="new" />
                  <div className="text">New Chat</div>
                </div>
                <div className="right">
                  <div className="action-icon">STRG</div>
                  <div className="action-icon">K</div>
                </div>
              </div>
            </div>
            <div className="d-flex mt-2">
              <div className="frame-container">
                <div className="frame2">
                  <div className="KPI-cards">
                    <div className="div-2">
                      <div className="div-3">
                        <div className="start-your-first-wrapper">
                          <p className="start-your-first">
                            Start your first <br />
                            conversation now
                          </p>
                        </div>
                        <div
                          className={`new-chat w-100 ${
                            isHovered ? "hovered" : ""
                          }`}
                          onClick={handleNewChat}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <div className="left">
                            <img
                              className="new-chat-icon"
                              src={AddIconDark}
                              alt="new"
                            />
                            <div className="text">New Chat</div>
                          </div>
                          <div className="right">
                            <div className="action-icon">STRG</div>
                            <div className="action-icon">K</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <KpiCard
                    title="SHARED CONVERSATIONS"
                    value="0"
                    description="No shared conversation"
                  />
                  <KpiCard
                    title="CONVERSATIONS SHARED TO ME"
                    value="0"
                    description="No conversation shared to you"
                  />
                </div>
              </div>
              <div className="frame3">
                <div className="div">
                  <div className="icon-wrapper">
                    <div className="icon">
                      <img src={Trendfile} alt="Trendfile Icon" />
                    </div>
                  </div>
                  <p className="currently-there-are">
                    Currently, there are <br />
                    no trending topics
                  </p>
                  <p className="things-seem-a-bit">
                    Things seem a bit quiet at the moment. But don't worry, the
                    world never stays silent for long.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
