import { signIn } from "Common/helper/helper";

export const openCenteredPopup = async (
  url: string,
  title: string,
  w: number,
  h: number
) => {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
        scrollbars=no,
        toolbar=no,
        location=no,
        directories=no,
        status=no,
        menubar=no,
        resizable=no,
        width=${w / systemZoom}, 
        height=${h / systemZoom}, 
        top=${top}, 
        left=${left}
        `
  );

  newWindow?.focus();
  windowCloseTimer(newWindow);
  window.onmessage = function (result) {
    console.log(result);
    if (result.data.token) {
      signIn(result.data.token, "name", "email");
      localStorage.setItem("token", result.data.token);
      window.location.reload();
    }
  };
};

export const newWindowPopup = async (
  url: string,
  title: string,
  w: number,
  h: number
) => {
  const newWindow = window.open(url, "_self");

  newWindow?.focus();
  windowCloseTimer(newWindow);
  window.onmessage = function (result) {
    console.log(result);
    if (result.data.token) {
      signIn(result.data.token, "name", "email");
      localStorage.setItem("token", result.data.token);
      window.location.reload();
    }
  };
};

const windowCloseTimer = (windowRef: any) => {
  setTimeout(() => {
    if (windowRef && !windowRef.closed) {
      windowRef.close();
    }
  }, 30000); // 5 min
};
