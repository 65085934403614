//constants
import {
  LAYOUT_TYPES,
  LAYOUT_SIDEBAR_TYPES,
  LAYOUT_MODE_TYPES,
  LAYOUT_WIDTH_TYPES,
  LAYOUT_POSITION_TYPES,
  LAYOUT_TOP_BAR_THEME_TYPES,
  LEFT_SIDEBAR_SIZE_TYPES,
  LEFT_SIDEBAR_VIEW_TYPES,
  LEFT_SIDEBAR_IMAGE_TYPES,
  PER_LOADER_TYPES,
  SIDEBAR_VISIBILITY_TYPES,
} from "../../Constant/layout";

export const initialState = {
  layoutType: LAYOUT_TYPES.VERTICAL,
  leftSidebarType: LAYOUT_SIDEBAR_TYPES.DARK,
  layoutModeType: LAYOUT_MODE_TYPES.LIGHT_MODE,
  layoutWidthType: LAYOUT_WIDTH_TYPES.FLUID,
  layoutPositionType: LAYOUT_POSITION_TYPES.FIXED,
  topBarThemeType: LAYOUT_TOP_BAR_THEME_TYPES.LIGHT,
  leftSidebarSizeType: LEFT_SIDEBAR_SIZE_TYPES.SMALL_ICON,
  leftSidebarViewType: LEFT_SIDEBAR_VIEW_TYPES.DEFAULT,
  leftSidebarImageType: LEFT_SIDEBAR_IMAGE_TYPES.NONE,
  preloader: PER_LOADER_TYPES.DISABLE,
  sidebarVisibilityType: SIDEBAR_VISIBILITY_TYPES.SHOW,
};

const changeHTMLAttribute = (attribute: any, value: any) => {
  if (document.documentElement)
    document.documentElement.setAttribute(attribute, value);
  return true;
};
export { changeHTMLAttribute };
