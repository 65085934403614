import { create } from "zustand";

//constants
import {
  LAYOUT_TYPES,
  LAYOUT_SIDEBAR_TYPES,
  LAYOUT_MODE_TYPES,
  LAYOUT_WIDTH_TYPES,
  LAYOUT_POSITION_TYPES,
  LAYOUT_TOP_BAR_THEME_TYPES,
  LEFT_SIDEBAR_SIZE_TYPES,
  LEFT_SIDEBAR_VIEW_TYPES,
  LEFT_SIDEBAR_IMAGE_TYPES,
  PER_LOADER_TYPES,
  SIDEBAR_VISIBILITY_TYPES,
} from "../../Constant/layout";

import { changeHTMLAttribute } from "./layout-helper";

const layoutStore = (set: any) => ({
  layoutType: LAYOUT_TYPES.VERTICAL,
  leftSidebarType: LAYOUT_SIDEBAR_TYPES.LIGHT,
  layoutModeType: LAYOUT_MODE_TYPES.LIGHT_MODE,
  layoutWidthType: LAYOUT_WIDTH_TYPES.FLUID,
  layoutPositionType: LAYOUT_POSITION_TYPES.FIXED,
  topBarThemeType: LAYOUT_TOP_BAR_THEME_TYPES.LIGHT,
  leftSidebarSizeType: LEFT_SIDEBAR_SIZE_TYPES.SMALL_ICON,
  leftSidebarViewType: LEFT_SIDEBAR_VIEW_TYPES.DEFAULT,
  leftSidebarImageType: LEFT_SIDEBAR_IMAGE_TYPES.NONE,
  preloader: PER_LOADER_TYPES.DISABLE,
  sidebarVisibilityType: SIDEBAR_VISIBILITY_TYPES.SHOW,

  changeLayout: async (layout: any) => {
    try {
      if (layout === LAYOUT_TYPES.TWO_COLUMN) {
        document.documentElement.removeAttribute("data-layout-width");
      } else if (layout === LAYOUT_TYPES.HORIZONTAL) {
        document.documentElement.removeAttribute("data-sidebar-size");
      } else if (layout === LAYOUT_TYPES.SEMI_BOX) {
        changeHTMLAttribute("data-layout-width", "fluid");
        changeHTMLAttribute("data-layout-style", "default");
      }
      changeHTMLAttribute("data-layout", layout);
      set(() => ({ layoutType: layout }));
    } catch (error) {}
  },

  changeLayoutMode: async (layoutMode: any) => {
    try {
      changeHTMLAttribute("data-layout-mode", layoutMode);
      set(() => ({ layoutModeType: layoutMode }));
    } catch (error) {}
  },

  changeSidebarTheme: async (theme: any) => {
    try {
      changeHTMLAttribute("data-sidebar", theme);
      set(() => ({ LAYOUT_SIDEBAR_TYPES: theme }));
    } catch (error) {
      // console.log(error);
    }
  },

  changeLayoutWidth: async (layoutWidth: any) => {
    try {
      if (layoutWidth === LAYOUT_WIDTH_TYPES.FLUID) {
        changeHTMLAttribute("data-layout-width", "fluid");
      } else {
        changeHTMLAttribute("data-layout-width", "boxed");
      }
      set(() => ({ layoutWidthType: layoutWidth }));
    } catch (error) {
      return error;
    }
  },

  changeLayoutPosition: async (layoutPosition: any) => {
    try {
      changeHTMLAttribute("data-layout-position", layoutPosition);
      set(() => ({ layoutPositionType: layoutPosition }));
    } catch (error) {
      // console.log(error);
    }
  },

  changeTopBarTheme: async (topBarTheme: any) => {
    try {
      changeHTMLAttribute("data-topbar", topBarTheme);
      set(() => ({ topBarThemeType: topBarTheme }));
    } catch (error) {
      // console.log(error);
    }
  },

  changeSidebarImageType: async (leftSideBarImageType: any) => {
    try {
      changeHTMLAttribute("data-sidebar-image", leftSideBarImageType);
      set(() => ({ leftSidebarImageType: leftSideBarImageType }));
    } catch (error) {
      // console.log(error);
    }
  },

  changePreLoader: async (PER_LOADER_TYPES: any) => {
    try {
      changeHTMLAttribute("data-preloader", PER_LOADER_TYPES);
      set(() => ({ preloader: PER_LOADER_TYPES }));
    } catch (error) {
      // console.log(error);
    }
  },

  changeLeftSidebarSizeType: async (leftSidebarSizeType: any) => {
    try {
      switch (leftSidebarSizeType) {
        case LEFT_SIDEBAR_SIZE_TYPES.DEFAULT:
          changeHTMLAttribute("data-sidebar-size", "lg");
          break;
        case LEFT_SIDEBAR_SIZE_TYPES.COMPACT:
          changeHTMLAttribute("data-sidebar-size", "md");
          break;
        case LEFT_SIDEBAR_SIZE_TYPES.SMALL_ICON:
          changeHTMLAttribute("data-sidebar-size", "sm");
          break;
        case LEFT_SIDEBAR_SIZE_TYPES.SMALL_HOVER:
          changeHTMLAttribute("data-sidebar-size", "sm-hover");
          break;
        default:
          changeHTMLAttribute("data-sidebar-size", "lg");
      }
      set(() => ({ leftSidebarSizeType: leftSidebarSizeType }));
    } catch (error) {
      // console.log(error);
    }
  },

  changeLeftSidebarViewType: async (leftSidebarViewType: any) => {
    try {
      changeHTMLAttribute("data-layout-style", leftSidebarViewType);
      set(() => ({ leftSidebarViewType: leftSidebarViewType }));
    } catch (error) {
      // console.log(error);
    }
  },

  changeSidebarVisibility: async (sidebarVisibilityType: any) => {
    try {
      changeHTMLAttribute("data-sidebar-visibility", sidebarVisibilityType);
      set(() => ({ sidebarVisibilityType: sidebarVisibilityType }));
    } catch (error) {}
  },
});

const useLayoutStore = create(layoutStore);

export default useLayoutStore;
