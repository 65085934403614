import { Input } from "reactstrap";
import GptIcon from "assets/images/GptIcon.svg";
import CopyIcon from "assets/images/Icon/CopyIcon.svg";
import LikeIcon from "assets/images/Icon/LikeIcon.svg";
import LikeColorIcon from "assets/images/Icon/ColorLikeIcon.svg";
import DislikeIcon from "assets/images/Icon/DislikeIcon.svg";
import DislikeColorIcon from "assets/images/Icon/DislikeColorIcon.svg";
import { MessageResponseCardProps } from "Common/Interface";
import { CHAT_ACTION } from "Constant/type";
import toast from "react-hot-toast";

const MessageResponseCard = (props: MessageResponseCardProps) => {
  const { msg, activeStatus, action, handleAction, chatId, chatSessionId } =
    props;

  const handleCopy = (msg: string) => {
    navigator.clipboard.writeText(msg);
    toast.success("text copied");
  };

  return (
    <div className={`message-card response ${activeStatus ? "active" : ""}`}>
      <div className="head">
        <div className="profile">
          <img src={GptIcon} alt="profile" />
        </div>
        <div className="content">
          <div className="top">
            <div className="title">hkp /// GPT</div>
            <div className="message">{msg}</div>
            <div className="action"></div>
          </div>
          <div className="message-card-footer">
            <div className="icons">
              <img src={CopyIcon} alt="copy" onClick={() => handleCopy(msg)} />
              {action === CHAT_ACTION.UNDO && (
                <>
                  <img
                    src={LikeIcon}
                    alt="like"
                    onClick={() =>
                      handleAction(chatId, chatSessionId, CHAT_ACTION.LIKE)
                    }
                  />
                  <img
                    src={DislikeIcon}
                    alt="dislike"
                    onClick={() =>
                      handleAction(chatId, chatSessionId, CHAT_ACTION.DISLIKE)
                    }
                  />
                </>
              )}
              {action === CHAT_ACTION.LIKE && (
                <>
                  <img
                    src={LikeColorIcon}
                    alt="like"
                    onClick={() =>
                      handleAction(chatId, chatSessionId, CHAT_ACTION.UNDO)
                    }
                  />
                  <img
                    src={DislikeIcon}
                    alt="dislike"
                    onClick={() =>
                      handleAction(chatId, chatSessionId, CHAT_ACTION.DISLIKE)
                    }
                  />
                </>
              )}

              {action === CHAT_ACTION.DISLIKE && (
                <>
                  <img
                    src={LikeIcon}
                    alt="like"
                    onClick={() =>
                      handleAction(chatId, chatSessionId, CHAT_ACTION.LIKE)
                    }
                  />
                  <img
                    src={DislikeColorIcon}
                    alt="dislike"
                    onClick={() =>
                      handleAction(chatId, chatSessionId, CHAT_ACTION.UNDO)
                    }
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="action">
          <div className="form-check">
            <Input className="form-check-input" type="checkbox" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageResponseCard;
