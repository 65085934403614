import React, { useEffect } from "react";

//import Components
// import Header from "./Header";
import Sidebar from "./Sidebar";
// import Footer from "./Footer";
import withRouter from "../../Common/Components/withRouter";
import { Store } from "Store";
import { LAYOUT_TYPES, SIDEBAR_VISIBILITY_TYPES } from "Constant/layout";

const Layout = (props: any) => {
  // const [headerClass, setHeaderClass] = useState("");

  const hamburgerIcon = document.querySelector(".hamburger-icon");

  const {
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topVarThemeType,
    leftSidebarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    sidebarVisibilityType,
    changeLeftSidebarViewType,
    changeLeftSidebarSizeType,
    changeSidebarTheme,
    changeLayoutMode,
    changeLayoutWidth,
    changeLayoutPosition,
    changeTopBarTheme,
    changeLayout,
    changeSidebarImageType,
    changeSidebarVisibility,
  } = Store.Layout((state: any) => ({
    layoutType: state.layoutType,
    leftSidebarType: state.leftSidebarType,
    layoutModeType: state.layoutModeType,
    layoutWidthType: state.layoutWidthType,
    layoutPositionType: state.layoutPositionType,
    topVarThemeType: state.topVarThemeType,
    leftSidebarSizeType: state.leftSidebarSizeType,
    leftSidebarViewType: state.leftSidebarViewType,
    leftSidebarImageType: state.leftSidebarImageType,
    sidebarVisibilityType: state.sidebarVisibilityType,
    changeLeftSidebarViewType: state.changeLeftSidebarViewType,
    changeLeftSidebarSizeType: state.changeLeftSidebarSizeType,
    changeSidebarTheme: state.changeSidebarTheme,
    changeLayoutMode: state.changeLayoutMode,
    changeLayoutWidth: state.changeLayoutWidth,
    changeLayoutPosition: state.changeLayoutPosition,
    changeTopBarTheme: state.changeTopBarTheme,
    changeLayout: state.changeLayout,
    changeSidebarImageType: state.changeSidebarImageType,
    changeSidebarVisibility: state.changeSidebarVisibility,
  }));

  /*
   layout settings
   */
  useEffect(() => {
    if (
      layoutType ||
      leftSidebarType ||
      layoutModeType ||
      layoutWidthType ||
      layoutPositionType ||
      topVarThemeType ||
      leftSidebarSizeType ||
      leftSidebarViewType ||
      leftSidebarImageType ||
      sidebarVisibilityType
    ) {
      window.dispatchEvent(new Event("resize"));
      changeLeftSidebarViewType(leftSidebarViewType);
      changeLeftSidebarSizeType(leftSidebarSizeType);
      changeSidebarTheme(leftSidebarType);
      changeLayoutMode(layoutModeType);
      changeLayoutWidth(layoutWidthType);
      changeLayoutPosition(layoutPositionType);
      changeTopBarTheme(topVarThemeType);
      changeLayout(layoutType);
      changeSidebarImageType(leftSidebarImageType);
      changeSidebarVisibility(sidebarVisibilityType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topVarThemeType,
    leftSidebarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    sidebarVisibilityType,
  ]);

  // class add remove in header
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      // setHeaderClass("topbar-shadow");
    } else {
      // setHeaderClass("");
    }
  }

  useEffect(() => {
    if (
      sidebarVisibilityType === SIDEBAR_VISIBILITY_TYPES.SHOW ||
      layoutType === LAYOUT_TYPES.VERTICAL ||
      layoutType === LAYOUT_TYPES.TWO_COLUMN
    ) {
      if (hamburgerIcon != null) {
        hamburgerIcon.classList.remove("open");
      }
    } else {
      if (hamburgerIcon != null) hamburgerIcon.classList.add("open");
    }
  }, [sidebarVisibilityType, layoutType, hamburgerIcon]);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        {/* <Header
          headerClass={headerClass}
        /> */}
        <Sidebar layoutType={layoutType} />
        <div className="main-content">
          {props.children}
          {/* <Footer /> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Layout);
