import BaseApiService from "./baseService";

class ChatService extends BaseApiService {
  constructor() {
    super("users");
  }

  chat(params: any, signal?: AbortSignal | undefined) {
    return this.post("chat", params, signal);
  }

  getPrompts(params: any) {
    return this.get(`dashboard-prompt`, params);
  }

  getSessions(params: any) {
    return this.get("chat-sessions", params);
  }

  getHistory(params: any) {
    return this.post(`chat-history/${params}`, {});
  }

  updateChatSession(params: any) {
    return this.put(`chat-sessions/${params.id}`, params);
  }

  chatAction(params: any) {
    return this.post(`chat-action`, params);
  }
}

const ChatServiceObj = new ChatService();
export default ChatServiceObj;
