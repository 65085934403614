import { signOut } from "Common/helper/helper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "Store";

const ProfileSection = () => {
  const navigate = useNavigate();
  const { currentUser } = Store.User();
  const [userDetails, setUserDetails] = useState<any>(null);

  useEffect(() => {
    if (currentUser) {
      setUserDetails(currentUser);
    }
  }, [currentUser]);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
    signOut();
  };

  return (
    <div className="profile">
      <div className="user-profile-image">
        {userDetails && userDetails?.first_name && userDetails?.first_name[0]}
        {userDetails?.last_name
          ? userDetails?.last_name[0]
          : userDetails?.first_name
          ? userDetails?.first_name[1]
          : null}
      </div>
      <div className="logout" onClick={handleLogout}>
        Logout
      </div>
    </div>
  );
};

export default ProfileSection;
