import { Input } from "reactstrap";
import GptIcon from "assets/images/GptIcon.svg";

const GenerateResponseCard = () => {
  return (
    <div className="message-card response">
      <div className="profile">
        <img src={GptIcon} alt="profile" />
      </div>
      <div className="content">
        <div className="title">hkp /// GPT</div>
        <div className="message">Generating ....</div>
        <div className="action"></div>
      </div>
      <div className="action">
        <div className="form-check">
          <Input className="form-check-input" type="checkbox" />
        </div>
      </div>
    </div>
  );
};

export default GenerateResponseCard;
