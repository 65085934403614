export const MESSAGE_TYPE = {
  QUESTION: 0,
  ANSWER: 1,
};

export const CHAT_ACTION = {
  UNDO: 0,
  LIKE: 1,
  DISLIKE: 2,
};

export const CHAT_TYPE = {
  PUBLIC: 0,
  PRIVATE: 1,
};

export const SESSION_UPDATE_TYPE = {
  FAVORITE: "FAVORITE",
  NAME: "NAME",
};
