import userService from "API/userService";
import ChatModal from "Module/Chat/Components/ChatModal/ChatModal";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Store } from "Store";

const AuthProtected = (props: any) => {
  const navigate = useNavigate();
  const { setCurrentUser, setLoading } = Store.User();
  const { setChatModalStatus, chatModalStatus } = Store.Chat();

  const [profile, setProfile] = useState<any | null>(null);

  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if ((event.metaKey || event.ctrlKey) && event.key === "k") {
        setChatModalStatus(!chatModalStatus);

        event.preventDefault();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchProfile() {
    const authTokenString = window.sessionStorage.getItem("token");
    const user = window.sessionStorage.getItem("user");

    if (!authTokenString && !user) {
      navigate("/login");
    } else {
      setProfile(user);
      await callApi();
    }
  }

  const callApi = async () => {
    setLoading(true);
    await getCurrentUserApi();
    setLoading(false);
  };

  const getCurrentUserApi = async () => {
    try {
      setLoading(true);
      const { data } = await userService.currentUser({});
      setCurrentUser(data?.user);
      setLoading(false);
    } catch (error: any) {
      if (error.response.status === 401) {
        toast.error(
          "Your session has expired. Please log in again to continue."
        );
        window.sessionStorage.clear();
        navigate("/login");
      }
      setLoading(false);
    }
  };

  if (!profile) {
    return null;
  }

  return (
    <>
      {props.children}
      {chatModalStatus && <ChatModal />}
    </>
  );
};

export { AuthProtected };
