import { create } from "zustand";
const chatStore = (set: any) => ({
  chatSessionId: "",
  chatPromptMsg: "",
  chatPromptStatus: true,
  chatModalStatus: false,
  chatModalSubmitStatus: false,
  initialChatStatus: false,
  initialChatData: {},
  chatCancelToken: null,
  isChatTypeChange: false,
  removedHistoryData: [],
  chatTypeStatus: 0,
  isGenerating: false,

  setChatSessionId: (id: string) => {
    set(() => ({ chatSessionId: id }));
  },

  setChatPromptMsg: (msg: string) => {
    set(() => ({ chatPromptMsg: msg }));
  },

  setPromptCardStatus: (status: boolean) => {
    set(() => ({ chatPromptStatus: status }));
  },

  setChatModalStatus: (status: boolean) => {
    set(() => ({ chatModalStatus: status }));
  },

  setChatModalSubmitStatus: (status: boolean) => {
    set(() => ({ chatModalSubmitStatus: status }));
  },

  setInitialChatData: (id: string, title: string) => {
    set(() => ({ initialChatData: { id: id, msg: title } }));
  },

  setInitialChatStatus: (status: boolean) => {
    set(() => ({ initialChatStatus: status }));
  },

  setChatCancelToken: (token: any) => {
    set(() => ({ chatCancelToken: token }));
  },

  setChangeChatType: (type: boolean) => {
    set(() => ({ isChatTypeChange: type }));
  },

  setRemovedHistoryData: (data: any[]) => {
    set(() => ({ removedHistoryData: data }));
  },

  setChatTypeStatus: (status: number) => {
    set(() => ({ chatTypeStatus: status }));
  },

  setIsGenerating: (status: boolean) => {
    set(() => ({ isGenerating: status }));
  },
});

const useChatStore = create(chatStore);

export default useChatStore;
