import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { AuthToken } from "Common/Interface";
import { CONFIG } from "Constant/config";

class BaseApiService {
  REACT_APP_API_URL =
    process.env.REACT_APP_API_URL || "https://chat-api.visions.team/";
  baseURL: string;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
    this.setupAuthInterceptor();
  }

  setupAuthInterceptor() {
    axios.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        // Add your authentication logic here, e.g., adding a token to headers
        const authTokenString = window.sessionStorage.getItem("token");
        if (authTokenString) {
          const authToken: AuthToken = authTokenString as unknown as AuthToken;
          if (config.headers) {
            config.headers["Password-Authentication"] =
              CONFIG.loginWithPassword;
            if (CONFIG.loginWithPassword) {
              config.headers.Authorization = `Bearer ${authToken}`;
            } else {
              config["withCredentials"] = true;
            }
          }
        }
        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );
  }

  post(route = "", postData = {}, signal?: AbortSignal | undefined) {
    return axios.post(
      `${this.REACT_APP_API_URL}${this.baseURL}/${route}`,
      postData,
      { signal: signal }
    );
  }

  put(route = "", putData = {}) {
    return axios.put(
      `${this.REACT_APP_API_URL}${this.baseURL}/${route}`,
      putData
    );
  }

  get(route: string, params: any) {
    let url = `${this.REACT_APP_API_URL}${this.baseURL}/`;
    if (route === "") {
      url = `${this.REACT_APP_API_URL}${this.baseURL}`;
    }
    return axios.get(`${url}${route}`, { params });
  }

  handleError(error: any) {
    console.log(error);
    let errorMessage =
      "An error occurred while processing your request. Please try again later.";

    switch (error.response ? error.response.status : null) {
      case 0:
        errorMessage =
          "Unable to connect to the server. Please check your internet connection and try again later.";
        break;
      case 400:
        errorMessage = `Error: ${error.response.data.error.message}. Please try again later.`;
        break;
      case 401:
        errorMessage =
          "Unauthorized access. Please log in and try again later.";
        break;
      case 403:
        errorMessage = `Error: ${error.response.data.error.message}.`;
        break;
      case 504:
        errorMessage =
          "We are sorry, but we could not get a timely response from the server. This might be due to a temporary issue, high server load, or connectivity problems. Refresh the page and try again. If the issue persists, contact support team for assistance.";
        break;
      case 500:
        errorMessage =
          "We apologize, but our server encountered an internal error while processing your request. Refresh the page and try again. If the problem persists, contact support team for assistance.";
        break;
      default:
        errorMessage = `Backend returned code ${
          error.response ? error.response.status : "unknown"
        }, body was: ${
          error.response ? JSON.stringify(error.response.data) : "unknown"
        }`;
    }
    console.log(errorMessage);
    return Promise.reject(errorMessage);
  }
}

export default BaseApiService;
