import ChatSideBar from "./Components/SideBar/Sidebar";
import Prompt from "./Components/Prompt/Prompt";
import { useEffect, useState } from "react";
import { Container } from "reactstrap";
import LeftIcon from "../../assets/images/Icon/LeftIcon.svg";
import RightIcon from "../../assets/images/Icon/RightIcon.svg";
import ChatComponent from "./Components/Chat/Chat";
import { Store } from "Store";
import { useParams } from "react-router-dom";

const Chat = () => {
  const [showChatSideBar, setShowChatSideBar] = useState(false);

  const {
    chatPromptStatus,
    chatModalSubmitStatus,
    chatCancelToken,
  } = Store.Chat((state: any) => ({
    chatPromptStatus: state.chatPromptStatus,
    chatModalSubmitStatus: state.chatModalSubmitStatus,
    chatModalStatus: state.chatModalStatus,
    chatCancelToken: state.chatCancelToken,
  }));

  const { sessionId } = useParams();

  useEffect(() => {
    if (chatPromptStatus && !chatModalSubmitStatus && !sessionId) {
      chatCancelToken?.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatPromptStatus, chatModalSubmitStatus, sessionId]);

  const toogleSideBar = () => {
    setShowChatSideBar(!showChatSideBar);
  };

  return (
    <div className="page-content">
      <Container
        fluid
        className={`chat-container ${showChatSideBar ? "" : "show"}`}
      >
        <div className="h-100">
          <div className="chat">
            <ChatSideBar showStatus={showChatSideBar} />
            <div>
              <div
                className={`${
                  showChatSideBar ? "toggle-icon" : "toggle-icon right"
                }`}
                onClick={toogleSideBar}
              >
                {!showChatSideBar ? (
                  <img
                    className="toggle-icon-size"
                    src={RightIcon}
                    alt="left"
                  />
                ) : (
                  <img className="toggle-icon-size" src={LeftIcon} alt="left" />
                )}
              </div>
            </div>
            {chatPromptStatus && !chatModalSubmitStatus && !sessionId ? (
              <Prompt showStatus={showChatSideBar} />
            ) : (
              <ChatComponent showStatus={showChatSideBar} />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Chat;
