import Login from "../Module/User/Login/Login";
import { Navigate } from "react-router-dom";
import Dashboard from "../Module/Dashboard/Dashboard";
import Chat from "../Module/Chat/index";

export const allRoutes = [
  { path: "/login", component: <Login />, auth: false },
  { path: "/dashboard", component: <Dashboard />, auth: true },
  { path: "/chat/:chatType?/:sessionId?", component: <Chat />, auth: true },
  { path: "*", component: <Navigate to="/dashboard" />, auth: true },
];
