import { Input } from "reactstrap";
import { Store } from "Store";

const MessageCard = (props: any) => {
  const { msg, activeStatus } = props;
  const { currentUser } = Store.User((state: any) => ({
    currentUser: state.currentUser,
  }));
  return (
    <div className={`message-card ${activeStatus ? "active" : ""}`}>
      <div className="profile">
        <div className="user-profile">
          {currentUser && currentUser?.first_name && currentUser?.first_name[0]}
        </div>
      </div>
      <div className="content">
        <div className="title">YOU</div>
        <div className="message">{msg}</div>
      </div>
      <div className="message-card-footer">
        <div className="form-check">
          <Input className="form-check-input" type="checkbox" />
        </div>
      </div>
    </div>
  );
};

export default MessageCard;
